import React from "react"
import { Formik } from "formik"
import { useIdentityContext } from "react-netlify-identity"
import { bool, object, string } from "yup"
import { navigate } from "gatsby"
import { errorMessages, errorMessagesPL } from "@const"

import SignUpUser from "./SignUpUser"

export default () => {
  const { loginUser, signupUser } = useIdentityContext()
  const formikProps = {
    initialValues: {
      acceptPrivacyPolicy: false,
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      subscribeToNewsletter: false
    },
    onSubmit: ({ email, firstName, lastName, password, subscribeToNewsletter }, {setFieldError, setSubmitting}) => {
      const data = { firstName, lastName, subscribeToNewsletter }
      const directLogin = true
      signupUser(email, password, data, directLogin)
        .then(response => {
          if (response.confirmation_sent_at) {
            navigate("/register-summary")
          }
          if (response.confirmed_at) {
            loginUser(email, password, true)
            navigate("/")
          }
          setSubmitting(false)
        })
        .catch(error => {
          const isAlreadyInUseEmail = String(error).includes(errorMessages.alreadyInUseEmail);
          if(isAlreadyInUseEmail) {
            setFieldError('email', errorMessagesPL.alreadyInUseEmail);
          }
          setSubmitting(false)
        })
    },
    validationSchema: object().shape({
      acceptPrivacyPolicy: bool().oneOf([true], "TermsOfServiceAccepted"),
      email: string().email(errorMessages.emailNotValid).required(errorMessages.required),
      password: string().min(8, errorMessages.passwordTooShort).required(errorMessages.required),
    }),
  }
  return (
    <Formik {...formikProps} children={props => <SignUpUser {...props} />} />
  )
}
