import React from "react"
import { Flex, NavLink, Styled } from "theme-ui"
import { Link } from "gatsby"
import SignUpUser from "@forms/SignUpUser"
import Layout from "@layouts/form"

export default () => (
  <Layout title="Rejestracja">
    <Styled.h4
      style={{
        color: `#000`,
        fontSize: `22px`,
        lineHeight: `25px`,
        textAlign: `center`,
      }}
    >
      Zarejestruj się i poznaj dodatkowe benefity
    </Styled.h4>
    <Styled.p
      style={{
        letterSpacing: `0px`,
        color: `#7D7D7D`,
        fontSize: `13px`,
        lineHeight: `25px`,
        fontFamily: `Poppins`,
        fontWeight: 400,
        textAlign: `center`,
        paddingTop: '2rem',
        paddingBottom: '2rem'
      }}
    >
      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      Lorem Ipsum has been the industry's standard dummy text ever since the
      1500s
    </Styled.p>
    <SignUpUser />
    <Flex>
      <NavLink
        as={Link}
        to="/login"
        sx={{
          color: `#000`,
          textAlign: "right",
          fontWeight: 400,
          fontFamily: 'Poppins',
          fontSize: `13px`,
          letterSpacing: "0px",
          pt: "1rem",
        }}
      >
        Masz już konto?
      </NavLink>
    </Flex>
  </Layout>
)
