import React from "react"
import { Input, Label } from "theme-ui"
import { useField } from "formik";

export default ({children, placeholder, ...props}) => {
  const [field, meta, helpers] = useField(props);

  return (
    <Label>
      <Input
        type="text"
        placeholder={placeholder}
        {...field}
        {...meta}
        {...helpers}
        {...props}
      />
      {children}
    </Label>
  )
}